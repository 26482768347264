import React, {Suspense, useEffect} from 'react';
import './App.scss';
import {HashRouter, Route, Switch, useHistory} from 'react-router-dom';
import loadable from 'loadable-components';
import {Amplify, Auth} from 'aws-amplify';
import awsConfig from './config/aws-exports';
import {useAppDispatch} from './redux/reduxHooks';
import axios from 'axios';
import {clearAppUser, setAppUser} from './redux/slices/appStateSlice';
import { isExpired } from 'react-jwt';
import { Ripple } from '@progress/kendo-react-ripple';


const auth = loadable(() => import('./components/layouts/auth/AuthLayout'));
const app = loadable(() => import('./components/layouts/app/AppLayout'));

function App() {
    Amplify.configure(awsConfig);
    const history = useHistory();
    const dispatch = useAppDispatch();
    

    useEffect(() => {
       
        axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
        Auth.currentAuthenticatedUser({
            bypassCache: false,
        })
            .then((user) => {
                const token = user.signInUserSession.accessToken.jwtToken;
                const isMyTokenExpired = isExpired(token);
                if (isMyTokenExpired) {
                    localStorage.clear();
                    dispatch(clearAppUser());
                    history.replace('/');
                } else {
                    axios.defaults.headers.Authorization = 'Bearer ' + token;
                    axios.interceptors.response.use(
                        (response) => {
                            return response;
                        },
                        (error) => {
                            if (error.response.status === 401) {
                                dispatch(clearAppUser());
                                localStorage.clear();
                            }
                            return Promise.reject(error);
                        }
                    );
                    dispatch(
                        setAppUser({
                            username: user.username,
                            attributes: user.attributes,
                        })
                    );
                }
            })
            .catch((err) => {
                console.log('auth.currentAuthenticatedUser:', err);
                dispatch(clearAppUser());
                localStorage.clear();
            });
    }, [dispatch, history]);

  return (
    <HashRouter>
      <Suspense fallback={''}>
        <Switch>
          <Ripple>
            <Route exact path="/" component={auth} />
            <Route path="/auth" component={auth} />
            <Route path="/app" component={app} />
          </Ripple>
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

export default App;
