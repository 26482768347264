import { configureStore } from '@reduxjs/toolkit';
import appStateReducer from './slices/appStateSlice';
import mainStateReducer from './slices/mainStateSlice';
import accessPolicyReducer from './slices/accessPolicySlice';


export const store = configureStore({
  reducer: {
    appState: appStateReducer,
    mainState: mainStateReducer,
    access_policy: accessPolicyReducer,
  },
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
