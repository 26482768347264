import { createSlice } from '@reduxjs/toolkit';

interface AppState {
  user: {
    isLoggedIn: boolean;
    username: string;
    user_role?: number;
  };
  mainSnackbarOpen: boolean;
  mainSnackbarMessage: string;
  mainSnackbarType: string;

}


const initialState: AppState = {
  user: {
    isLoggedIn: false,
    username: '',
    user_role: undefined,
  },
  mainSnackbarOpen: false,
  mainSnackbarMessage: '',
  mainSnackbarType: 'success',
};


export const appStateSlice = createSlice({
  name: 'appState',
  initialState: initialState,
  reducers: {
    clearAppUser: (state) => {
      state.user = {
        isLoggedIn: false,
        username: '',
        user_role: undefined,
      };
    },
    setAppUser: (state, action) => {
      state.user = {
        isLoggedIn: true,
        username: action.payload.username,
        user_role: +action.payload.attributes['custom:userrole'],
      };
    },
    openSnackbar: (state, action) => {
      state.mainSnackbarOpen = action.payload.mainSnackbarOpen;
      state.mainSnackbarMessage = action.payload.mainSnackbarMessage;
      state.mainSnackbarType = action.payload.mainSnackbarType;
    },
    closeSnackbar: (state) => {
      state.mainSnackbarOpen = false;
      state.mainSnackbarMessage = '';
      state.mainSnackbarType = 'success';
    },
  },
});

export const { clearAppUser, setAppUser, openSnackbar, closeSnackbar} = appStateSlice.actions;

export default appStateSlice.reducer;
