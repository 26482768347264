import { createSlice } from '@reduxjs/toolkit';


const initialState: any = {}
export const accessPolicySlice = createSlice({
  name: 'accessPolicyState',
  initialState,
  reducers: {
    setInitAccessPolicy: (state, action) => {
      const temp: any = {};
      action.payload.features.forEach((a: any) => (temp['f_' + a.key + '_allowed'] = a.allowed));
      action.payload.resources.forEach((a: any) => {
        temp['r_' + a.key + '_allowed'] = a.allowed;
        let resourceOperations = '';
        resourceOperations += a.operations.createAllowed ? '1' : '';
        resourceOperations += a.operations.readAllowed ? '2' : '';
        resourceOperations += a.operations.updateAllowed ? '3' : '';
        resourceOperations += a.operations.deleteAllowed ? '4' : '';
        temp['r_' + a.key + '_operations'] = resourceOperations;

        a.attributes.forEach((b: any) => {
          temp['a_' + b.key + '_allowed'] = b.allowed;
          let AttrOperations = '';
          AttrOperations += b.operations.readAllowed ? '2' : '';
          AttrOperations += b.operations.updateAllowed ? '3' : '';
          temp['a_' + b.key + '_operations'] = AttrOperations;
        });
      });

      return temp;
    },
  },
});

export const { setInitAccessPolicy } = accessPolicySlice.actions;

export default accessPolicySlice.reducer;
